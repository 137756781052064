import React from 'react';
import './Home.css';
import Product from './Product';

function Home() {
  return (
    <div className="home">
      <div className="home__container">
        <img
          className="home__image"
          src="https://daviduriel.com/app-data/amazon-clone/images/amazon_hero-1.jpg"
          alt="Amazon Banner"
        />

        <div className="home__row">
          <Product
            id="9437289256"
            title="Tommy Hilfiger Logo Sweatshirt"
            price={49.99}
            image="https://m.media-amazon.com/images/I/31k1-aASTGL._AC_SY240_.jpg"
            rating={4}
          />
          <Product
            id="6451231635"
            title="All-new Echo Dot (4th Gen) | Smart speaker with Alexa | Charcoal"
            price={15.99}
            image="https://m.media-amazon.com/images/I/51ydNSj0AiL.__AC_SY200_.jpg"
            rating={5}
          />
        </div>

        <div className="home__row">
          <Product
            id="8368468468"
            title="Bose QuietComfort 35 II Wireless Bluetooth Headphones, Noise-Cancelling, with Alexa voice control - Black"
            price={199.99}
            image="https://m.media-amazon.com/images/I/41jSuUHT8eL.__AC_SY200_.jpg"
            rating={4}
          />
          <Product
            id="356743574567"
            title="Instant Pot Duo Plus 9-in-1 Electric Pressure Cooker, Sterilizer, Slow Cooker, Rice Cooker, Steamer, saute, Yogurt Maker, and Warmer, 6 Quart, 15 One-Touch Programs"
            price={69.99}
            image="https://m.media-amazon.com/images/I/41adOywmTfL.__AC_SY200_.jpg"
            rating={3}
          />
          <Product
            id="87978978"
            title='All-new Fire HD 8 tablet, 8" HD display, 64 GB, designed for portable entertainment, Black'
            price={84.99}
            image="https://m.media-amazon.com/images/I/41s2owUfoqL.__AC_SY200_.jpg"
            rating={5}
          />
        </div>

        <div className="home__row">
          <Product
            id="254764t734657"
            title="Nixplay Smart Digital Picture Frame 10.1 Inch, Share Video Clips and Photos Instantly via E-Mail or App"
            price={115.99}
            image="https://m.media-amazon.com/images/I/41ZnaRd33dL.__AC_SY200_.jpg"
            rating={4}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
