import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCfiEnQKFdIabtgjyGpAylP_SeFHGilQLQ',
  authDomain: 'amznclone-4f49a.firebaseapp.com',
  databaseURL: 'https://amznclone-4f49a.firebaseio.com',
  projectId: 'amznclone-4f49a',
  storageBucket: 'amznclone-4f49a.appspot.com',
  messagingSenderId: '1063061361295',
  appId: '1:1063061361295:web:d7c74ead9a2c70ffcb96fa',
  measurementId: 'G-T4B9J8LBKH',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
